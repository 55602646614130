<template>
  <div class="container-component">
    <div class="container-left">
      <div class="left-tree">
        <div class="tree-title">
          <span>人员列表</span>
        </div>
        <div class="tree-list">
          <el-tree ref="tree" :data="departmentTree" node-key="id" empty-text="暂无部门" :show-checkbox="false" :highlight-current="true" @node-click="checkNode"></el-tree>
        </div>
      </div>
    </div>
    <div class="container-right">
      <div class="right-top">
        <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增人员({{nodeName||'暂未选择部门'}})</el-button>
      </div>
      <div class="right-center">
        <el-table :data="memberList" stripe style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="100" :index='indexMethod'> </el-table-column>
          <el-table-column prop="realname" label="人员名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tooltip content="修改" placement="top" effect="dark">
                <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" effect="dark">
                <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delMember(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="right-bottom">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加人员':'修改人员'" :visible.sync="memberShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="member" ref="memberForm" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所属部门" :label-width="formLabelWidth">
              <span style="color:#fff;">{{nodeName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="用户账号" prop="username" :label-width="formLabelWidth">
              <el-input v-model="member.username" placeholder="请输入用户账号" size="small" :maxlength="10" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="所属岗位" prop="unitRoleId" :label-width="formLabelWidth">
              <el-select v-model="member.unitRoleId" placeholder="请选择类型" size="small" clearable>
                <el-option v-for="(item,i) in roleList" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="所属工种" prop="job" :label-width="formLabelWidth">
              <el-select v-model="member.job" placeholder="请选择工种" size="small" clearable>
                <el-option v-for="(item,i) in jobTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="员工名称" prop="realname" :label-width="formLabelWidth">
              <el-input v-model="member.realname" placeholder="请输入员工名称" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="性别" prop="sex" :label-width="formLabelWidth">
              <el-select v-model="member.sex" placeholder="请选择性别" size="small" clearable>
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="身份证号" prop="idCard" :label-width="formLabelWidth">
              <el-input v-model="member.idCard" placeholder="请输入身份证号" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="联系电话" prop="phoneNumber" :label-width="formLabelWidth">
              <el-input v-model="member.phoneNumber" placeholder="请输入联系电话" size="small" :maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span>
        <el-button @click="memberShow = false">取 消</el-button>
        <el-button type="primary" @click="addMember" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="editMember" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      memberList: [],
      departmentTree: [],
      departmentId: '',
      formLabelWidth: '100px',
      currentPage: 1,
      total: 0,
      memberShow: false,
      member: {
        idCard: '',
        job: '',
        phoneNumber: '',
        realname: '',
        sex: '',
        unitRoleId: '',
        username: ''
      },
      memberCopy: {},
      memberId: '',
      optFlag: false,
      nodeName: '',
      jobTypeList: [],
      roleList: [],
      rules: {
        username: [
          { required: true, message: '请输入用户账号', trigger: 'blur' }
        ],
        unitRoleId: [
          { required: true, message: '请选择所属岗位', trigger: 'change' }
        ],
        job: [
          { required: true, message: '请选择所属工种', trigger: 'change' }
        ]
        // realname: [
        //   { required: true, message: '请输入员工姓名', trigger: 'blur' }
        // ],
        // sex: [
        //   { required: true, message: '请选择性别', trigger: 'change' }
        // ],
        // idCard: [
        //   { required: true, message: '请输入身份证号', trigger: 'blur' },
        //   { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号' }
        // ],
        // phoneNumber: [
        //   { required: true, message: '请输入联系电话', trigger: 'blur' },
        //   { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请输入正确的联系电话' }
        // ]
      }
    }
  },
  created () {
    this.initTree()
    this.memberCopy = JSON.stringify(this.member)
  },
  mounted () {
    this.initTable()
    this.getJobType()
    this.getRole()
  },
  methods: {
    indexMethod (index) {
      const curpage = this.currentPage // 单前页码，具体看组件取值
      const limitpage = 10 // 每页条数，具体是组件取值
      return index + 1 + (curpage - 1) * limitpage
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    checkNode (node) {
      this.nodeName = node.label
      this.departmentId = node.id
      this.getRole()
      this.initTable()
    },
    async initTable () {
      const data = {}
      data.pageSize = 10
      data.pageNo = this.currentPage
      data.departmentId = this.departmentId
      const { data: result } = await this.$axios.get('/user/list', { params: data })
      if (result.code === 200) {
        this.memberList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async initTree () {
      const { data: result } = await this.$axios.post('/department/treeDepartment')
      if (result.code === 200) {
        this.departmentTree = result.data
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.memberShow = true
          this.$nextTick(() => {
            this.$refs.memberForm.clearValidate()
          })
          break
        case 'edit':
          this.optFlag = false
          this.memberShow = true
          this.$nextTick(() => {
            this.$refs.memberForm.clearValidate()
          })
          this.member = JSON.parse(JSON.stringify(row))
          break
      }
    },
    // 添加人员
    addMember () {
      this.$refs.memberForm.validate(async valid => {
        if (valid) {
          this.member.departmentId = this.departmentId
          const { data: result } = await this.$axios.post('/unitUser/add', this.member)
          if (result.code === 200) {
            this.$message.success('添加成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.memberShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改人员
    editMember () {
      this.$refs.memberForm.validate(async valid => {
        if (valid) {
          this.member.departmentId = this.departmentId
          const { data: result } = await this.$axios.put(`/unitUser/edit/${this.member.id}`, this.member)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.memberShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除人员
    async delMember (id) {
      const confirm = await this.$confirm('此操作将永久删除该人员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/user/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 查找角色
    async getRole () {
      const { data: result } = await this.$axios.get('/unitRole/list', { params: { departmentId: this.departmentId } })
      if (result.code === 200) {
        this.roleList = result.data
      }
    },
    // 查找工种
    async getJobType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'jobType' } })
      if (result.code === 200) {
        this.jobTypeList = result.data
      }
    },
    // 关闭弹窗清空表单
    clear () {
      this.member = JSON.parse(this.memberCopy)
      // this.$refs.deviceForm.resetFileds()
      this.member = {
        idCard: '',
        job: '',
        phoneNumber: '',
        realname: '',
        sex: '',
        unitRoleId: '',
        username: ''
      }
      this.$refs.memberForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.container-component {
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 10px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  position: relative;
  background-image: url("../../../assets/img/table-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .container-left {
    flex: 1;
    margin-right: 10px;
    padding-bottom: 10px;
    .left-tree {
      height: 100%;
      .tree-title {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
      .el-tree {
        background-color: transparent;
        color: #fff;
        ::v-deep .el-tree-node__content:hover {
          background-color: rgba(30, 74, 115, 0.5);
        }
        ::v-deep .el-tree-node:focus > .el-tree-node__content {
          background-color: rgba(30, 74, 115, 0.5);
        }
      }
    }
  }
  .container-right {
    flex: 4;
    display: flex;
    flex-direction: column;
    .right-top {
      flex: 1;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: flex-start;
      .el-button {
        margin-right: auto;
      }
      .el-form {
        height: 40px;
      }
    }
    .right-center {
      flex: 9;
      ::v-deep .el-table,
      .el-table__expanded-cell {
        background-color: transparent;
        tr,
        th {
          background-color: transparent;
          color: #fff;
          .el-table__expand-icon {
            color: #fff;
          }
        }
        .el-table--enable-row-hover,
        .hover-row > td,
        tr:hover > td {
          background-color: rgba(28, 97, 160, 0.8) !important;
        }
        .el-table__row--striped > td {
          background-color: rgba(44, 74, 130, 0.2) !important;
        }
        .current-row > td {
          background-color: rgba(7, 104, 159, 0.5) !important;
        }
      }
    }
    .right-bottom {
      flex: 1;
      display: flex;
      align-items: center;
      ::v-deep .el-pagination {
        color: #fff;
        background-color: transparent;
        .el-pagination__total,
        .el-pagination__jump {
          color: #fff;
        }
        .btn-prev,
        .btn-next {
          background-color: transparent;
          color: #fff;
        }
        .el-pager li {
          background-color: transparent !important;
        }
        .btn-quicknext {
          color: #fff;
        }
      }
    }
  }
}
</style>
